<template>
  <step-lesson-layout :title="$t('module1.part4.lesson1.title')">
    <template v-slot:content>
      <lesson-video-block video="/videos/histoire-cee.mp4"
        subtitles="/subtitles/histoire-cee.vtt"
        :button-label="$t('module1.part4.lesson1.videoButton')"
        :poster="require('@/assets/module1/part4/poster-histoire-cee.svg')"
        v-on:next-tab="$emit('next-tab')">
      </lesson-video-block>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import LessonVideoBlock from '@/components/training/LessonVideoBlock'

export default {
  name: 'Module1Part4Lesson1',
  components: { LessonVideoBlock, StepLessonLayout },
  emits: ['next-tab']
}
</script>
